import React, { useState, useEffect } from "react";
import { useLocation  } from "react-router-dom";

export const Body = () => {
  const [Data, setData] = useState([]);

  const location = useLocation()
  console.log('Path:',location.pathname.split("/")[2])

  useEffect(() => {
    fetch("https://api.forecast.ac/stock?&symbol="+ location.pathname.split("/")[2] +"")
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        setData(data[0]);
      });
  }, []);

  return (
    <div className="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
      <div className="container-fluid">
        <div className="row g-3 mb-3 align-items-center">
          <div className="col">
            <ol className="breadcrumb bg-transparent mb-0">
              <li className="breadcrumb-item">
                <a className="text-secondary" href="index.html">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </div>
        </div>{" "}
        {/* .row end */}
        <div className="row align-items-center">
          <div className="col">
            <h1 className="fs-5 color-900 mt-1 mb-0">{Data.Name}</h1>
            <small className="text-muted">
              You have 12 new messages and 7 new notifications.
            </small>
          </div>
          <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-12 mt-2 mt-md-0">
            {/* daterange picker */}
            <div className="input-group">
              <input className="form-control" type="text" name="daterange" />
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="tooltip"
                aria-label="Send Report"
                data-bs-original-title="Send Report"
              >
                <i className="fa fa-envelope" />
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="tooltip"
                aria-label="Download Reports"
                data-bs-original-title="Download Reports"
              >
                <i className="fa fa-download" />
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="tooltip"
                aria-label="Generate PDF"
                data-bs-original-title="Generate PDF"
              >
                <i className="fa fa-file-pdf-o" />
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="tooltip"
                aria-label="Share Dashboard"
                data-bs-original-title="Share Dashboard"
              >
                <i className="fa fa-share-alt" />
              </button>
            </div>
            {/* Plugin Js */}
            {/* Jquery Page Js */}
          </div>
        </div>{" "}
        {/* .row end */}
      </div>
    </div>
  );
};
