import React from "react";
import { NavBar } from "../Compenents/NavBar";
import { Header } from "../Compenents/Header";
import { Body } from "../Compenents/Body";
import { Footer } from "../Compenents/Footer";
export const Quote = () => {


  return (
    <>
      <NavBar />
      <div class="wrapper">
        <Header />
<div class="page-body body-layout-1" >
<div class="project-list d-flex flex-nowrap">

<div className="order-1 custom_scroll">
  <button
    type="button"
    className="btn bg-secondary text-light mb-3 w-100"
    data-bs-toggle="modal"
    data-bs-target="#CreateNew"
  >
    New Project
  </button>
  <ul
    className="nav nav-tabs menu-list list-unstyled mb-0 border-0"
    role="tablist"
  >
    <li className="nav-item" role="presentation">
      <a
        className="nav-link active"
        href="#"
        data-bs-toggle="tab"
        data-bs-target="#project_all"
        role="tab"
        aria-selected="true"
      >
        <i className="fa fa-tasks" />
        <span>All Project</span>
        <span className="badge bg-light text-dark ms-2 ms-auto">18</span>
      </a>
    </li>
    <li className="nav-item" role="presentation">
      <a
        className="nav-link"
        href="#"
        data-bs-toggle="tab"
        data-bs-target="#project_progress"
        role="tab"
        aria-selected="false"
        tabIndex={-1}
      >
        <i className="fa fa-product-hunt" />
        <span>In Progress</span>
        <span className="badge bg-light text-dark ms-2 ms-auto">3</span>
      </a>
    </li>
    <li className="nav-item" role="presentation">
      <a
        className="nav-link"
        href="#"
        data-bs-toggle="tab"
        data-bs-target="#project_coming"
        role="tab"
        aria-selected="false"
        tabIndex={-1}
      >
        <i className="fa fa-clock-o" />
        <span>Up Coming</span>
        <span className="badge bg-light text-dark ms-2 ms-auto">2</span>
      </a>
    </li>
    <li className="nav-item" role="presentation">
      <a
        className="nav-link"
        href="#"
        data-bs-toggle="tab"
        data-bs-target="#project_overdue"
        role="tab"
        aria-selected="false"
        tabIndex={-1}
      >
        <i className="fa fa-calendar" />
        <span>Overdue</span>
      </a>
    </li>
    <li className="nav-item" role="presentation">
      <a
        className="nav-link"
        href="#"
        data-bs-toggle="tab"
        data-bs-target="#project_done"
        role="tab"
        aria-selected="false"
        tabIndex={-1}
      >
        <i className="fa fa-check-circle" />
        <span>Completed</span>
      </a>
    </li>
    <li className="divider mt-4 py-2 border-top text-uppercase text-muted">
      <small>Project Revenue</small>
    </li>
    <li>
      <h2 className="fw-normal">$78,890</h2>
      <div className="my-3">
        <div className="mb-0 fw-bold">
          $5,250 <span className="fa fa-level-up text-success" />
        </div>
        <small className="text-muted">Project Profit</small>
      </div>
      <div>
        <div className="mb-0 fw-bold">
          $1,450 <span className="fa fa-level-down text-danger" />
        </div>
        <small className="text-muted">Project Expense</small>
      </div>
    </li>
    
  </ul>
</div>
<Body/>
</div>
</div>

        <Footer />
      </div>
    </>
  );
};

