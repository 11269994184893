import React from "react";
import { NavBar } from "../Compenents/NavBar";
import { Header } from "../Compenents/Header";
import { Body } from "../Compenents/Body";
import { Footer } from "../Compenents/Footer";

export const Home = () => {
  return (
    <>
      <NavBar />
      <div class="wrapper">
        <Header />
        <Body />
        <Footer />
      </div>
    </>
  );
};
